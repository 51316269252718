<template>
  <div class="app-container">
    <div class="top_btns">
      <div class="left_box">
        <el-input placeholder="请输入客户名称" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small" @keyup.enter="getList" />
        <el-button type="primary" size="small"   @click="getList">
          <el-icon><Search /></el-icon> <span  > 搜索</span>
        </el-button>
        <!-- <subject-list :addNotShow="true" v-model:subjectId="listQuery.subjectId" v-model:fzhsItemId="listQuery.fzhsId" @success="getList"></subject-list> -->
      </div>
      <div class="right_box">
        <qzf-video vid="4467be5374f8e7bc9833db2dae69e589_4"></qzf-video>

        <qzf-button size="small" type="success" @success="add"  >
          <el-icon><CirclePlus /></el-icon> <span  >新增</span>
        </qzf-button>
        <qzf-button size="small" type="danger" @click="delAll"  >
          <el-icon><Delete /></el-icon> <span  >批量删除</span>
        </qzf-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"> </el-table-column>
      <el-table-column align="center" prop="name" label="客户名称" min-width="180"> </el-table-column>
      <el-table-column align="center" prop="subjectName" label="对应科目" min-width="180"> </el-table-column>
      <el-table-column align="center" prop="fzhsName" label="对应辅助核算" min-width="150"> </el-table-column>
      <el-table-column align="center" prop="address" label="操作" width="180">
        <template #default="scope">
          <qzf-button @success="handleUpdate(scope.row)" size="small" type="text">编辑</qzf-button>
          <qzf-button size="small" @success="del(scope.row)" type="text">删除</qzf-button>
        </template>
      </el-table-column>
    </el-table>

      <!-- 分页 -->
    <div class="bottom_style">
      <div class="bottom_button">
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>

    <add-business ref="addBusiness"  @success="getList" :type="this.listQuery.type"></add-business>
  </div>
</template>

<script>
import { corpCoopList, corpCoopDel } from "@/api/coop"
export default {
  name:'commonContent',
  props: {
    tabName: String,
  },
  data() {
    return {
      select: [],
      list:[],
      total: 0,
      listQuery: {
        page: 1,
        limit: 20,
        type: "2",
      }
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(240)
    this.getList()
  },
  methods:{
    handleSelectionChange(e){
      this.select = e
    },
    getList(){
      corpCoopList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    handleUpdate(row){
      let data = {
        id: row.id,
        name: row.name,
        subjectId: row.subjectId,
        fzhsId: row.fzhsId,
      }
      this.$refs.addBusiness.init(data)
    },
    delAll(){
      if(this.select.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$confirm("确认删除", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.delCorp(this.select)
      })
    },
    del(row){
       this.$confirm("确认删除", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.delCorp([row])
      })
    },
    delCorp(params){
      corpCoopDel(params).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
      })
    },
    add(){
      this.$refs.addBusiness.init()
    },
  }
}
</script>

<style scoped lang="scss">
 
.pagination{
  margin-top: 15px;
}
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
</style>