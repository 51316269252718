<template>
  <div class="app-container">
    <div class="top_btns">
      <div class="left_box">
        <el-input placeholder="请输入账户名称" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small" @keyup.enter="getList" />
        <el-button type="primary" size="small"   @click="getList">
          <el-icon><Search /></el-icon> <span  > 搜索</span>
        </el-button>
        <!-- <subject-list :addNotShow="true" v-model:subjectId="listQuery.subjectId" v-model:fzhsItemId="listQuery.fzhsId" @success="getList"></subject-list> -->
      </div>
      <div class="right_box">
        <qzf-video vid="4467be5374e08f6530aaf9a709fd8d9b_4"></qzf-video>

        <qzf-button size="small" type="success" @success="add"  >
          <el-icon><CirclePlus /></el-icon> <span  >新增</span>
        </qzf-button>
        <qzf-button size="small" type="danger" @success="delAll"  >
          <el-icon><Delete /></el-icon> <span  >批量删除</span>
        </qzf-button>
      </div>
    </div>
      <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="name" label="账户名称" min-width="180"> </el-table-column>
        <el-table-column align="center" prop="subjectName" label="对应科目" min-width="180"> </el-table-column>
        <el-table-column align="center" prop="bankNo" label="银行主账号" min-width="180"> </el-table-column>
        <el-table-column align="center" prop="xybh" label="协议编号" min-width="260">
          <template #default="scope">
            <span v-if="scope.row.xybh">
              <i class="iconfont icon-duihao">已签约</i>
              {{ scope.row.xybh }}
            </span>
            <span v-else><i class="iconfont icon-gantanhao">未签约</i></span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="currency" label="币种" min-width="100"> </el-table-column>
        <el-table-column align="center" prop="xybh" label="协议" min-width="120">
          <template #default="scope">
            <qzf-button @success="showAgreement(scope.row)" size="small" type="text">协议</qzf-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="操作" min-width="160">
          <template #default="scope">
            <!-- <qzf-button @success="copyMessage(scope.row.xybh)" size="small" type="text">复制编号</qzf-button> -->
            <qzf-button @success="handleUpdate(scope.row)" size="small" type="text">编辑</qzf-button>
            <qzf-button size="small" @success="del(scope.row)" type="text">删除</qzf-button>
          </template>
        </el-table-column>
      </el-table>

      <!--  签订协议 -->
      <el-dialog :close-on-click-modal="false"
        v-model="dialogAssianVisible"
        :title=this.bankName
        width="30%"
        destroy-on-close
        append-to-body
      >
      <el-table :data="bankBankList" border>
        <el-table-column property="date" label="银行名称" min-width="150">
          <template #default="scope">
            <span >{{ scope.row.bankName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="协议" min-width="260">
          <template #default="scope">
            <qzf-button @success="signAgreement()" size="small" type="text">签订协议</qzf-button>
            <qzf-button size="small" @success="getXyNo()" type="text">获取协议编号</qzf-button>
          </template>
        </el-table-column>
      </el-table>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="dialogAssianVisible = false">取消</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog :close-on-click-modal="false"
        v-model="dialogAssianVisibleOpen"
        title='协议打开链接'
        width="30%"
        destroy-on-close
        append-to-body
        center
      >
        <div>{{ this.agreementLink }}</div>
        <div style="font-size:12px;color:red;margin-top: 20px;">
          （提示：请在IE浏览器中打开）
        </div>
        
        <template #footer>
          <span class="dialog-footer">
            <el-button size="default" type="primary" @click="copyMessage(this.agreementLink)">
              <el-icon  style="cursor: pointer;font-size: 15px;position: relative"><DocumentCopy /></el-icon>复制链接
            </el-button>
          </span>
        </template>
      </el-dialog>

      <addBank ref="addBank" @success="getList"></addBank>
  </div>
</template>

<script>
import { bankNameList , bankNameDel ,agreement ,icbcXyNo,getIcbcDz } from "@/api/bank"
import { wbList } from "@/api/subject"
import addBank from "./addBank.vue"
export default {
  name:'bankAccount',
  components:{
    addBank
  },
  data() {
    return {
      list:[],
      listQuery:{
        page:1,
        limit:20
      },
      wbOptions:[],
      total:0,
      multipleSelection:[],
      bankBankList:[
        {bankName:'工商银行'}
      ],
      dialogAssianVisible:false,
      bankId:0,
      agreementLink:'',
      dialogAssianVisibleOpen:false
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(240)
    this.getList()
    this.init()
  },
  methods:{
    init(){
      wbList({}).then(res=>{
        this.wbOptions = res.data.data.list
      })
    },
    getList(){
      bankNameList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    add(){
      this.$refs.addBank.init(this.wbOptions)
      // this.$refs.addBank.dialogFormVisible = true
      this.$refs.addBank.bianji('add')

    },
    handleUpdate(row){
      this.$refs.addBank.init(this.wbOptions)
      this.$refs.addBank.bianji(row)
    },
    del(row){
      bankNameDel([row]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
      })
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    delAll(){
      console.log(this.multipleSelection);
      if(this.multipleSelection.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$confirm("确定删除选项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        bankNameDel(this.multipleSelection).then(res=>{
          this.$qzfMessage("删除成功")
          this.getList();
        })
      }); 
    },
    // 签订协议
    showAgreement(row){
      this.dialogAssianVisible = true
      this.bankName = row.name
      this.bankId = row.id
    },
    signAgreement(){
      agreement({id:this.bankId}).then(res => {
        if(res.data.msg == "success"){
          this.dialogAssianVisibleOpen = true
          this.agreementLink = res.data.data
        }
      })
    },
    // 获取协议编号
    getXyNo(){
      icbcXyNo({id:this.bankId}).then(res => {
        if(res.data.msg == "success"){
          this.dialogAssianVisible = false
          this.$qzfMessage("获取成功")
          this.getList()
        }
      })
    },
    copyMessage(value){
      console.log(value);
			const newObj =  value
			//创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = newObj;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('复制成功！')
      //移除input标签
      document.body.removeChild(input) 
		},
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
 
</style>